<template>
	<div>
		<v-tabs
			v-model="tab"
			background-color="wr_grey_1"
			color="black"
			grow
			slider-color="error"
		>
			<v-tab
				v-for="(item, index) in questionnaires"
				:key="index"
				active-class="black--text font-weight-bold"
			>
				{{ item.title }}
			</v-tab>
		</v-tabs>

		<v-tabs-items v-model="tab" class="wr_grey_1">
			<v-tab-item v-for="(item, index) in questionnaires" :key="index">
				<div class="wr_grey_1 mt-7" flat>
					<AppQuestionnaire
						v-if="questionnaire_data"
						:prop_questionnaire="questionnaire_data"
					></AppQuestionnaire>
				</div>
			</v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
import AppQuestionnaire from "@/components/ui/AppQuestionnaire.vue";

export default {
	name: "QuestionnaireWork",

	data() {
		return {
			tab: 0,
			questionnaire_data: null,
		};
	},

	components: {
		AppQuestionnaire,
	},

	computed: {
		questionnaires() {
			return [
				{
					title: this.$t("app.choose_theme.title"),
					name: this.$defines.QUESTIONNAIRE.q10,
				},
				{
					title: this.$t("app.choose_topic.title"),
					name: this.$defines.QUESTIONNAIRE.q30,
				},
				{
					title: this.$t("app.choose_question.title"),
					name: this.$defines.QUESTIONNAIRE.q50,
				},
				{
					title: this.$t("app.define_question.title"),
					name: this.$defines.QUESTIONNAIRE.q60,
				},
				{
					title: this.$t("app.gather_sources.title"),
					name: this.$defines.QUESTIONNAIRE.q70,
				},
				{
					title: this.$t("app.write_research.title"),
					name: this.$defines.QUESTIONNAIRE.q80,
				},
				{
					title: this.$t("app.reflect_journey.title"),
					name: this.$defines.QUESTIONNAIRE.q90,
				},
				{
					title: this.$t("app.present.title"),
					name: this.$defines.QUESTIONNAIRE.q100,
				},
			];
		},
	},

	watch: {
		tab: {
			handler() {
				// clear previous data
				this.questionnaire_data = null;
				// get new data
				this.getQuestionnaire();
			},
			immediate: true,
		},
	},

	methods: {
		async getQuestionnaire() {
			try {
				this.$loader.start();

				let response = await this.$store.dispatch("questionnaire/get", {
					questionnaire_name: this.questionnaires[this.tab].name,
				});

				if (response && response.answers.length) {
					this.questionnaire_data = response.answers;
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>

<style></style>
